export enum ApplicationType {
  NATIVE_APPLICATION = 'NATIVE_APPLICATION',
  NO_CODE_APPLICATION = 'NO_CODE_APPLICATION',
}

export enum NoCodeAppType {
  CODE_UPLOAD = 'CODE_UPLOAD',
  CUSTOM_PAGE = 'CUSTOM_PAGE', // Should be removed when PAGE_BUILDER will be added to DH
  EMBEDDED = 'EMBEDDED', // Should be removed when EMBEDDED_CODE & EMBEDDED_LINK will be added to DH
  EMBEDDED_CODE = 'EMBEDDED_CODE',
  EMBEDDED_LINK = 'EMBEDDED_LINK',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  MIRO_BOARD = 'MIRO_BOARD',
  PAGE_BUILDER = 'PAGE_BUILDER',
}

export enum ApplicationsFilterBy {
  AVAILABLE_APPLICATIONS = 'AVAILABLE_APPLICATIONS',
  FREE_APPLICATIONS = 'FREE_APPLICATIONS',
}

export enum CertificationLevel {
  COMING_SOON = 'COMING_SOON',
  CERTIFIED = 'CERTIFIED',
  GARAGE = 'GARAGE',
  STANDARD = 'STANDARD',
}

export enum MenuItems {
  ABOUT = 'about',
  FEATURES = 'features',
  RATING_AND_REVIEWS = 'rating-and-reviews',
  CASE_STUDIES = 'case-studies',
  RELATED_APPLICATIONS = 'related-applications',
}

export enum ApplicationReviewUsedFor {
  PITCH_WON = 'Pitch won',
  PITCH_LOST = 'Pitch lost',
  DAY_TO_DAY_WORK = 'Day-to-day work',
  WORKSHOP = 'Workshop',
  CAMPAIGN = 'Campaign',
  OTHER = 'Other',
}

export enum ApplicationReviewDetails {
  EASY_TO_USE = 'Easy to use',
  GREAT_FEATURE_SET = 'Great feature set',
  EFFICIENT_WORKFLOW = 'Efficient workflow',
  INTUITIVE_DESIGN = 'Intuitive design',
  FAST_LOADING_TIMES = 'Fast loading times',
  WORKS_AS_EXPECTED = 'Works as expected',
  DIFFICULT_TO_USE = 'Difficult to use',
  SLOW_LOADING_TIMES = 'Slow loading times',
  MISSING_KEY_FEATURES = 'Missing key features',
  BUGS_AND_ISSUES = 'Bugs & Issues',
}

export enum CommercialModel {
  CONTRACT = 'CONTRACT',
  FIXED_PRICE = 'FIXED_PRICE',
  FREE = 'FREE',
  PAY_AS_YOU_GO = 'PAY_AS_YOU_GO',
}

export enum Regions {
  EMEA = 'EMEA',
  NA = 'NA',
  APAC = 'APAC',
  LATAM = 'LATAM',
  GLOBAL = 'GLOBAL',
}

export enum ApplicationCategory {
  MEDIA = 'MEDIA',
  PRODUCTION = 'PRODUCTION',
  BRAND = 'BRAND',
  COMMERCE = 'COMMERCE',
  CREATIVE = 'CREATIVE',
  EXPERIENCE = 'EXPERIENCE',
  PR = 'PR',
  DATA = 'DATA',
  OTHER_APPS = 'OTHER_APPS',
}

export enum ApplicationMediaSubcategory {
  STRATEGY = 'STRATEGY',
  PLANNING = 'PLANNING',
  ACTIVATION = 'ACTIVATION',
  ANALYTICS = 'ANALYTICS',
}

export enum ApplicationProductionSubcategory {
  IDEATE = 'IDEATE',
  GENERATE = 'GENERATE',
  MAKE = 'MAKE',
  ADAPT = 'ADAPT',
  OPTIMISE = 'OPTIMISE',
  PUBLISH = 'PUBLISH',
}

export const ApplicationSubcategory = { ...ApplicationMediaSubcategory, ...ApplicationProductionSubcategory }

export const ApplicationCategoryLogo: Record<ApplicationCategory, string> = {
  [ApplicationCategory.MEDIA]: 'https://public-assets.os.wpp.com/logos/app-studio-media-studio.svg',
  [ApplicationCategory.PRODUCTION]: 'https://public-assets.os.wpp.com/logos/app-studio-production-studio.svg',
  [ApplicationCategory.BRAND]: 'https://public-assets.os.wpp.com/logos/app-studio-brand-studio.svg',
  [ApplicationCategory.COMMERCE]: 'https://public-assets.os.wpp.com/logos/app-studio-commerce-studio.svg',
  [ApplicationCategory.CREATIVE]: 'https://public-assets.os.wpp.com/logos/app-studio-creative-studio.svg',
  [ApplicationCategory.EXPERIENCE]: 'https://public-assets.os.wpp.com/logos/app-studio-crm-studio.svg',
  [ApplicationCategory.PR]: 'https://public-assets.os.wpp.com/logos/app-studio-pr-studio.svg',
  [ApplicationCategory.DATA]: 'https://public-assets.os.wpp.com/logos/app-studio-data.svg',
  [ApplicationCategory.OTHER_APPS]: 'https://public-assets.os.wpp.com/logos/app-studio-apps.svg',
}

export const ApplicationSubcategoryLogo: Record<keyof typeof ApplicationSubcategory, string> = {
  [ApplicationSubcategory.STRATEGY]: 'https://public-assets.os.wpp.com/logos/app-studio-media-studio-strategy.svg',
  [ApplicationSubcategory.PLANNING]: 'https://public-assets.os.wpp.com/logos/app-studio-media-studio-planning.svg',
  [ApplicationSubcategory.ACTIVATION]: 'https://public-assets.os.wpp.com/logos/app-studio-media-studio-activation.svg',
  [ApplicationSubcategory.ANALYTICS]: 'https://public-assets.os.wpp.com/logos/app-studio-media-studio-analytics.svg',
  [ApplicationSubcategory.IDEATE]: 'https://public-assets.os.wpp.com/logos/app-studio-production-studio-ideate.svg',
  [ApplicationSubcategory.GENERATE]: 'https://public-assets.os.wpp.com/logos/app-studio-production-studio-generate.svg',
  [ApplicationSubcategory.MAKE]: 'https://public-assets.os.wpp.com/logos/app-studio-production-studio-make.svg',
  [ApplicationSubcategory.ADAPT]: 'https://public-assets.os.wpp.com/logos/app-studio-production-studio-adapt.svg',
  [ApplicationSubcategory.OPTIMISE]: 'https://public-assets.os.wpp.com/logos/app-studio-production-studio-optimise.svg',
  [ApplicationSubcategory.PUBLISH]: 'https://public-assets.os.wpp.com/logos/app-studio-production-studio-publish.svg',
}

export enum Industry {
  AUTOMOTIVE = 'AUTOMOTIVE',
  BUSINESS_AND_PROFESSIONAL_SERVICES = 'BUSINESS_AND_PROFESSIONAL_SERVICES',
  CPG = 'CPG',
  ENERGY_AND_INFRASTRUCTURE = 'ENERGY_AND_INFRASTRUCTURE',
  FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
  GOVERNMENT_AND_PUBLIC_SECTOR = 'GOVERNMENT_AND_PUBLIC_SECTOR',
  HEALTHCARE_AND_PHARMA = 'HEALTHCARE_AND_PHARMA',
  LUXURY_AND_PREMIUM = 'LUXURY_AND_PREMIUM',
  RETAIL = 'RETAIL',
  TECH_AND_DIGITAL_SERVICES = 'TECH_AND_DIGITAL_SERVICES',
  TELECOMS = 'TELECOMS',
  MEDIA_AND_ENTERTAINMENT = 'MEDIA_AND_ENTERTAINMENT',
  TRAVEL_AND_LEISURE = 'TRAVEL_AND_LEISURE',
}

enum BenefitIcon {
  SYNC = 'WppIconSync',
  SEARCH = 'WppIconSearch',
  CAMPAIGN = 'WppIconCampaign',
  TARGET = 'WppIconTarget',
  PITCH = 'WppIconPitch',
  EXTENSION = 'WppIconExtension',
  BAR_CHART = 'WppIconBarChart',
  DATA_CLOUD_ON = 'WppIconDataCloudOn',
  MONEY = 'WppIconMoney',
  SUPPORT_CHAT = 'WppIconSupportChat',
  SHAPES = 'WppIconShapes',
  DESIGN = 'WppIconDesign',
}

export type BenefitIconType = `${BenefitIcon}`

export const BenefitIcons = Object.values(BenefitIcon)

export const FallbackBenefitIcon = 'WppIconError' as const
